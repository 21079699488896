document.addEventListener("DOMContentLoaded", function () {
    hideLoadingScreen();
  });
  
  function showLoadingScreen() {
    const loadingScreen = document.querySelector(".loading-screen");
    loadingScreen.style.display = "flex";
  }
  
  function hideLoadingScreen() {
    const loadingScreen = document.querySelector(".loading-screen");
    loadingScreen.style.display = "none";
  }
  
  const links = document.querySelectorAll("a");
  
  links.forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      showLoadingScreen();
      setTimeout(() => {
        window.location.href = e.target.href;
      }, 6000); // Adjust the delay time if needed
    });
  });
  